<template>
  <FormCard
    :title="ncm.title ? ncm.title : 'Novo NCM'"
    :status="ncm.status"
    @cancel="$router.push({ name: 'ncm' })"
    @save="validate"
  >
    <v-form
      class="px-5 mt-5"
      ref="form"
    >
      <v-row dense>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="ncm.title"
            label="Título"
            placeholder="Título do NCM"
            dense
            rounded
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="ncm.code"
            label="Código NCM"
            v-mask="'####.##.##'"
            placeholder="xxxx.xx.xx"
            dense
            rounded
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <v-textarea
            v-model="ncm.description"
            label="Descrição"
            auto-grow
            outlined
            dense
            rounded
          />
        </v-col>
      </v-row>
    </v-form>
  </FormCard>
</template>

<script>
export default {
  props: {
    valid: Boolean,
    ncm: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormCard: () => import('@/components/FormCard'),
  },
  data: () => ({
    profile: null,
  }),
  methods: {
    validate() {
      let isValid = this.$refs.form.validate()
      isValid ? this.$emit('save') : ''
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
  },
}
</script>
